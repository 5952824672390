import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import CTAButton from '../CTAButton';

const FormCTA = props => {
  const data = useStaticQuery(graphql`
    query {
      active: contentfulActiveLandingPage {
        page: activeLandingPage {
          cta
          ctaPath
        }
      }
    }
  `);

  return (
    <ButtonSpacer className={props.className}>
      <CTAButton href={data.active.page.ctaPath}>
        {data.active.page.cta}
      </CTAButton>
    </ButtonSpacer>
  );
};

export default FormCTA;

const ButtonSpacer = styled.div`
  text-align: center;
  margin: 60px 0;
`;
