import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Experts = props => {
  const data = useStaticQuery(graphql`
    query {
      contentfulActiveLandingPage {
        activeLandingPage {
          experts {
            firstName
            lastName
            title
            photo {
              title
              fixed(width: 132, height: 132, cropFocus: CENTER, quality: 90) {
                ...GatsbyContentfulFixed
              }
            }
            linkedin
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Container className={props.className}>
      <ContainerInner>
        {data.contentfulActiveLandingPage.activeLandingPage.experts.map(
          (expert, index) => {
            return (
              <Expert>
                <ImgWrapper key={index}>
                  <Img fixed={expert.photo.fixed} alt={expert.photo.title} />
                </ImgWrapper>
                <Name>{expert.firstName}</Name>
                <Name>{expert.lastName}</Name>
                <Title>{expert.title}</Title>
                <LinkedIn
                  href={expert.linkedin}
                  target="_blank"
                  icon={data.linkedin.publicURL}
                />
              </Expert>
            );
          }
        )}
      </ContainerInner>
    </Container>
  );
};

export default Experts;

const Container = styled.section`
  margin: 60px 0 120px;
`;

const ContainerInner = styled.div`
  margin: -30px -15px;
  display: flex;
  flex-wrap: wrap;
`;

const Expert = styled.div`
  padding: 30px 15px;
  flex: 0 0 100%;
  @media (min-width: 360px) {
    flex: 0 0 50%;
  }
  ${p => p.theme.m} {
    flex: 0 0 33.33333%;
  }
  ${p => p.theme.l} {
    flex: 0 0 25%;
  }
`;

const ImgWrapper = styled.div`
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.5em;
`;

const Name = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
`;

const Title = styled.p`
  font-size: 20px;
  line-height: 1.3;
  margin-top: 0.2em;
`;

const LinkedIn = styled.a`
  display: block;
  margin-top: 16px;
  width: 24px;
  height: 24px;
  background: url(${p => p.icon}) center center no-repeat;
  background-size: contain;
`;
