import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const XArea = props => {
  const data = useStaticQuery(graphql`
    query {
      xBackground: file(relativePath: { eq: "x-background-blue.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Container className={props.className}>
      <X background={data.xBackground.publicURL} />
      <Content>{props.children}</Content>
    </Container>
  );
};

export default XArea;

const Container = styled.section`
  position: relative;
`;

const X = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  position: absolute;
  left: 50%;
  top: 0;
  overflow: hidden;
  transform: translateX(-50%);
  ${p => p.theme.m} {
    transform: translateX(-70%);
  }
  ${p => p.theme.l} {
    transform: translateX(-90%);
  }
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    ${p => p.theme.s} {
      left: -20%;
    }
    bottom: 0;
    width: 100%;
    height: 120%;
    background: url('${p => p.background}');
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  padding: 20px 0;
`;
