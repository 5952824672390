import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const SlackButton = props => {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "arrow-right.svg" }) {
        publicURL
      }
      slack: file(relativePath: { eq: "slack.svg" }) {
        publicURL
      }
      slackIcon: file(relativePath: { eq: "slack-icon.svg" }) {
        publicURL
      }
      active: contentfulActiveLandingPage {
        page: activeLandingPage {
          conversationButton
          conversationButtonUrl
        }
      }
    }
  `);

  return (
    <Button
      className={props.className}
      href={data.active.page.conversationButtonUrl}
      onClick={e => {
        if (data.active.page.conversationButtonUrl === '#') {
          e.preventDefault();
          window.alert('This button is not supported yet.');
        }
      }}
      target="_blank"
    >
      <SlackLogo
        urlFull={data.slack.publicURL}
        urlIcon={data.slackIcon.publicURL}
      />
      <Label>{data.active.page.conversationButton}</Label>
      <Arrow url={data.arrow.publicURL} />
    </Button>
  );
};

export default SlackButton;

const Button = styled.a`
  position: relative;
  display: inline-block;
  height: 56px;
  line-height: 58px;
  background: white;
  box-shadow: 0px 2px 3px rgba(0, 43, 92, 0.1);
  border: 1px solid #d9ebf3;
  background-clip: padding-box;
  border-radius: 28px;
  font-family: Futura;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  padding: 0 24px;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 80px 0;
  @media (max-width: 374px) {
    padding: 0 15px;
    font-size: 14px;
  }
  ${p => p.theme.ml} {
    padding: 0 32px;
    font-size: 20px;
    height: 70px;
    line-height: 72px;
    border-radius: 35px;
  }
  :hover,
  :focus {
    opacity: 0.9;
  }
  :active {
    opacity: 0.85;
  }
`;

const SlackLogo = styled.div`
  flex: none;
  ${p => p.theme.s} {
    background: url('${p => p.urlIcon}') left center no-repeat;
    height: 100%;
    width: 24px;
    margin-right: 15px;
  }
  ${p => p.theme.ml} {
    margin-right: 30px;
    background: url('${p => p.urlFull}')  left center no-repeat;
    height: 100%;
    width: 80px;
  }
`;

const Label = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 480px) {
    margin-right: 30px;
  }
`;

const Arrow = styled.div`
  background: url('${p => p.url}');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  @media (max-width: 480px) {
    display: none;
  }
`;
