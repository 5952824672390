import styled from 'styled-components';

export default styled.div`
  font-size: 20px;
  ${p => p.theme.l} {
    font-size: 24px;
  }
  p {
    margin: 2em 0;
  }
  a {
    color: #0281ab;
  }
  ul,
  ol {
    margin: 2em 0;
    padding-left: 1.2em;
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
  hr {
    margin: 2em 0;
    border: none;
    border-top: 2px solid currentColor;
  }
  h1,
  h2,
  h3 {
    font-weight: bold;
    margin: 2em 0 1.5em;
  }
  h1 {
    font-size: 28px;
    ${p => p.theme.ml} {
      font-size: 36px;
    }
  }
  h2 {
    font-size: 26px;
    ${p => p.theme.ml} {
      font-size: 32px;
    }
  }
  h3 {
    font-size: 24px;
    ${p => p.theme.ml} {
      font-size: 28px;
    }
  }
  blockquote {
    margin: 2em 0;
    padding-left: 1em;
    border-left: 3px solid currentColor;
  }
`;
