import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Wrap from '../Wrap';
import H1 from '../H1';
import Paragraph from '../Paragraph';
import FormCTA from './FormCTA';

const Process = props => {
  const data = useStaticQuery(graphql`
    query {
      active: contentfulActiveLandingPage {
        page: activeLandingPage {
          offerTitle
          offerText {
            offerText
          }
          offerList {
            name
            text {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container className={props.className}>
      <BackgroundTop>
        <Wrap>
          <H1 css={{ marginTop: 0 }}>{data.active.page.offerTitle}</H1>
          <Paragraph css={{ whiteSpace: 'pre-line' }}>
            {data.active.page.offerText.offerText}
          </Paragraph>
        </Wrap>
      </BackgroundTop>
      <BackgroundBottom>
        <Wrap>
          <Items>
            {data.active.page.offerList.map((item, index) => {
              return (
                <Item key={index}>
                  <ItemName>{item.name}</ItemName>
                  <ItemImage>
                    <Img fluid={item.image.fluid} alt={item.image.title} />
                  </ItemImage>
                  <ItemDescription
                    dangerouslySetInnerHTML={{
                      __html: item.text.childMarkdownRemark.html,
                    }}
                  />
                </Item>
              );
            })}
          </Items>
        </Wrap>
        <FormCTA css={{ marginBottom: 0 }} />
      </BackgroundBottom>
    </Container>
  );
};

export default Process;

const Container = styled.section`
  margin: 120px 0;
`;

const BackgroundTop = styled.div`
  background: #eff5f7;
  padding-top: 50px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  ${p => p.theme.l} {
    margin: 0 60px;
  }
`;

const BackgroundBottom = styled.div`
  position: relative;
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #eff5f7;
    ${p => p.theme.l} {
      left: 60px;
      right: 60px;
      height: 180px;
      bottom: auto;
    }
  }
  ${p => p.theme.sm} {
    padding-bottom: 50px;
  }
`;

const Items = styled.div`
  position: relative;
  ${p => p.theme.l} {
    display: flex;
  }
`;

const Item = styled.div`
  ${p => p.theme.sm} {
    text-align: center;
    & + & {
      margin-top: 60px;
    }
  }
  ${p => p.theme.l} {
    flex: 1;
    & + & {
      margin-left: 20px;
    }
  }
`;

const ItemName = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 1.2;
  ${p => p.theme.l} {
    min-height: 67.2px;
  }
`;

const ItemImage = styled.div`
  margin-top: 27px;
  ${p => p.theme.sm} {
    width: 202px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ItemDescription = styled(Paragraph.withComponent('div'))`
  margin: 27px auto 0;
  white-space: pre-line;
  ${p => p.theme.sm} {
    max-width: 480px;
  }
  a {
    color: inherit;
  }
`;
