import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { parseISO, format } from 'date-fns';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import Paragraph from '../components/Paragraph';
import CTAButton from '../components/CTAButton';
import FormCTA from '../components/IndexPage/FormCTA';
import Process from '../components/IndexPage/Process';
import WhatWeOffer from '../components/IndexPage/WhatWeOffer';
import Experts from '../components/IndexPage/Experts';
import SlackButton from '../components/SlackButton';
import XArea from '../components/XArea';
import H1 from '../components/H1';
import Markdown from '../components/Markdown';

export const query = graphql`
  query($id: String!) {
    doc: contentfulPage(id: { eq: $id }) {
      showAccelerateMenu
      heroTitle1
      heroTitle2
      heroText {
        heroText
      }
      heroImage {
        fluid(maxWidth: 768, maxHeight: 512, cropFocus: CENTER, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
      contactHeader
      contactDesc {
        childMarkdownRemark {
          html
        }
      }
      columns {
        logo {
          file {
            url
          }
        }
        description {
          description
        }
        ctaTitle
        ctaLink
      }
      offerTitle
      offerText {
        offerText
      }
      offerList {
        name
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const BackgroundTop = styled.div`
  background: #eff5f7;
  padding-top: 50px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  ${p => p.theme.l} {
    margin: 0 60px;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 15px 50px 15px;
  display: flex;
  flex-direction: column;
`;

const ContactButton = styled.a`
  position: relative;
  display: inline-block;
  background: white;
  box-shadow: 0px 2px 3px rgba(0, 43, 92, 0.1);
  border: 1px solid #d9ebf3;
  background-clip: padding-box;
  border-radius: 28px;
  font-family: Futura;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  padding: 0 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 80px 0;
  @media (max-width: 374px) {
    padding: 0 15px;
    font-size: 14px;
  }
  p {
    margin: 1rem !important;
  }
  ${p => p.theme.ml} {
    padding: 0 32px;
    font-size: 20px;
    border-radius: 35px;
  }
  :hover,
  :focus {
    opacity: 0.9;
  }
  :active {
    opacity: 0.85;
  }
`;

const Container = styled.section`
  margin: 120px 0;
`;

const BackgroundBottom = styled.div`
  position: relative;
  :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #eff5f7;
    ${p => p.theme.l} {
      left: 60px;
      right: 60px;
      height: 180px;
      bottom: auto;
    }
  }
  ${p => p.theme.sm} {
    padding-bottom: 50px;
  }
`;

const Items = styled.div`
  position: relative;
`;

const Item = styled.div`
  margin-bottom: 20px;
  ${p => p.theme.sm} {
    text-align: center;
  }
  ${p => p.theme.l} {
  }
`;

const ItemName = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 1.2;
`;

const ItemImage = styled.div``;

const ItemDescription = styled(Paragraph.withComponent('div'))`
  margin: 0;
  white-space: pre-line;
  ${p => p.theme.sm} {
    max-width: 480px;
  }
  a {
    color: inherit;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: square;
  }
`;

const Page = ({ data }) => {
  console.log('data', data);
  return (
    <Layout hasXMenu={data.doc.showAccelerateMenu ? false : true}>
      <SEO title="Home" />
      <Hero
        image={data.doc.heroImage}
        title={[data.doc.heroTitle1, data.doc.heroTitle2]}
        titleIndent="second"
      />
      <Wrap>
        <Paragraph css={{ marginTop: 0, whiteSpace: 'pre-line' }}>
          {data.doc.heroText.heroText}
        </Paragraph>
      </Wrap>
      {data.doc.columns && (
        <BackgroundTop>
          <Wrap>
            <Columns>
              {data.doc.columns.map(col => (
                <Column>
                  {col.logo && (
                    <div>
                      <img src={col.logo.file.url} />
                    </div>
                  )}
                  <Paragraph>{col.description.description}</Paragraph>
                  {col.ctaTitle && (
                    <div style={{ marginTop: 'auto' }}>
                      <CTAButton>{col.ctaTitle}</CTAButton>
                    </div>
                  )}
                </Column>
              ))}
            </Columns>
          </Wrap>
        </BackgroundTop>
      )}
      {data.doc.offerList && (
        <Container>
          <BackgroundTop>
            <Wrap>
              <H1 css={{ marginTop: 0 }}>{data.doc.offerTitle}</H1>
              <Paragraph css={{ whiteSpace: 'pre-line' }}>
                {data.doc.offerText.offerText}
              </Paragraph>
            </Wrap>
            <Wrap>
              <Items>
                {data.doc.offerList.map((item, index) => {
                  return (
                    <Item key={index}>
                      <ItemName>{item.name}</ItemName>
                      {/*<ItemImage>
                        <Img fluid={item.image.fluid} alt={item.image.title} />
                      </ItemImage>*/}
                      <ItemDescription
                        dangerouslySetInnerHTML={{
                          __html: item.text.childMarkdownRemark.html,
                        }}
                      />
                    </Item>
                  );
                })}
              </Items>
            </Wrap>
          </BackgroundTop>
        </Container>
      )}
      <Wrap>
        <H1 css={{ alignText: 'left' }}>{data.doc.buildingTitle}</H1>
        <Paragraph css={{ whiteSpace: 'pre-line' }}>
          {/*data.doc.buildingText.buildingText*/}
        </Paragraph>
      </Wrap>
      {data.doc.contactDesc && (
        <Wrap>
          <>
            <H1>{data.doc.contactHeader}</H1>
            <ContactButton>
              <Markdown
                style={{ textAlign: 'center' }}
                dangerouslySetInnerHTML={{
                  __html: data.doc.contactDesc.childMarkdownRemark.html,
                }}
              />
            </ContactButton>
          </>
        </Wrap>
      )}
    </Layout>
  );
};

export default Page;

// const XArea = styled.div`
//   background: white
//     linear-gradient(
//       90deg,
//       rgba(217, 235, 243, 1) 0%,
//       rgba(217, 235, 243, 0.13) 50%
//     );
//   position: relative;

//   padding: 30px 0 30px;
//   ${p => p.theme.m} {
//     padding: 40px 0 40px;
//   }
//   ${p => p.theme.l} {
//     padding: 40px 0 140px;
//     :after {
//       position: absolute;
//       content: '';
//       bottom: 0;
//       height: 80px;
//       width: calc(100% - 120px);
//       max-width: 900px;
//       left: 50%;
//       transform: translateX(-50%);
//       background: white;
//     }
//   }
// `;

const ShiftedH1 = styled(H1)`
  text-align: left;
  ${p => p.theme.l} {
    margin-top: -20px;
    position: relative;
  }
`;

// const XImage = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   background: url('${p => p.image}');
//   background-size: contain;
//   background-position: top left;
//   background-repeat: no-repeat;
//   width: 100%;
//   ~ * {
//     position: relative;
//   }
// `;

// const CoverImage = styled.div`
//   position: relative;
// `;

// const Video = styled.video`
//   width: 100%;
// `;

// const Meta = styled.p`
//   margin: 20px 0 60px;
//   font-weight: bold;
//   span {
//     color: ${p => p.theme.color.accent};
//   }
//   font-size: 14px;
//   ${p => p.theme.l} {
//     font-size: 16px;
//   }
// `;
